define(['app', 'accessibleModalHelper', '$console'], function (app, accessibleModalHelper, console) {

  const guestNewsletterOptInModal = () => {
    const component = {};

    const _config = {
      selectors: {
        checkoutAsGuestButton: '[data-js-element=guest-checkout-trigger-modal-button]',
        closeButton: '.guestCheckoutNewsletterModal_close',
        email: '[data-e2e=guestUsername]',
        guestCheckoutForm: '[data-guest-checkout-form]',
        modal: '[data-guest-checkout-opt-in-modal]',
        newsletterStatusInput: '[data-guest-checkout-newsletter-status-input]',
        optInButton: '[data-guest-checkout-opt-in-button]',
        optOutButton: '[data-guest-checkout-opt-out-button]',
        newsletterModalTitle: '.guestCheckoutNewsletterModal_title',
      },
      classNames: {
        noScrollClass: 'guestCheckoutNewsletterModal_noscroll',
        hiddenClass: 'hidden',
      },
      attribs: {
        true: 'true'
      }
    };


    const _init = (element) => {
      component.element = element;
      component.guestCheckoutForm = component.element.querySelector(component.config.selectors.guestCheckoutForm);
      component.email =  component.element.querySelector(component.config.selectors.email);
      component.checkoutAsGuestButton = component.element.querySelector(component.config.selectors.checkoutAsGuestButton);
      component.checkoutAsGuestButton.addEventListener('click', component.checkIsSubscribed);
      component.newsletterStatusInput = component.element.querySelector(component.config.selectors.newsletterStatusInput);

      component.modal = component.element.querySelector(component.config.selectors.modal);
      component.closeButton = component.element.querySelector(component.config.selectors.closeButton);
      component.optInButton = component.element.querySelector(component.config.selectors.optInButton);
      component.optOutButton = component.element.querySelector(component.config.selectors.optOutButton);
      component.title = component.element.querySelector(component.config.selectors.newsletterModalTitle);

      return component;
    };

    const _checkIsSubscribed = (event) => {
      let email = component.email.value;
      event.preventDefault();
      app.publish('tracking/record', 'Continue as guest', 'Clicked');
      app.ajax.post({
        url: '/isEmailSubscribed.subscribe',
        send: JSON.stringify(email),
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json',
        },
        success: component.successHandler,
        error: component.errorHandler,
      })
    };

    const _errorHandler = (data) => {
      let url = new URL(window.location);
      let searchParam = new URLSearchParams(url.search);

      if (data === '"INVALID_EMAIL"') {
        searchParam.set("optInError", "email");
      }

      url.search = searchParam.toString();
      window.location = url;
    };

    const _successHandler = (data) => {
      if (data === '"SUBSCRIBED"') {
        component.newsletterStatusInput.value =  component.config.attribs.true;
        component.submitGuestCheckoutForm();
      } else if (data === '"NOT_SUBSCRIBED"'){
        component.showGuestCheckoutNewsletterModal();
      } else {
        console.error('ERROR: Not expected response');
        component.submitGuestCheckoutForm();
      }
    };

    const _showGuestCheckoutNewsletterModal = () => {
      component.closeButton.addEventListener('click', component.closeGuestCheckoutNewsletterModal);
      component.optInButton.addEventListener('click', () => component.submitNewsletterPreferences(true));
      component.optOutButton.addEventListener('click', () => component.submitNewsletterPreferences(false));
      component.modal.classList.remove(component.config.classNames.hiddenClass);
      document.body.classList.add(component.config.classNames.noScrollClass);
      component.accessibleModalHelper = new accessibleModalHelper(component.modal, component.closeGuestCheckoutNewsletterModal, component.title);
    };

    const _closeGuestCheckoutNewsletterModal = () => {
      component.modal.classList.add(component.config.classNames.hiddenClass);
      document.body.classList.remove(component.config.classNames.noScrollClass);
      component.accessibleModalHelper.close();
      app.publish('tracking/record', 'Opt In Guest Checkout Modal', 'Close Modal Button');
      app.publish('columbo/track', 'Opt In Guest Checkout Modal', 'Close Modal Button');
    };

    const _submitNewsletterPreferences = (optIn) => {
      let email = component.email.value;
      app.ajax.post({
        url: '/subscribeToNewsletter.subscribe?newsletterStatus=' + optIn,
        send: JSON.stringify(email),
        requestHeader: {
          header: 'content-type',
          value: 'application/json',
        },
        success: () => {
          component.newsletterStatusInput.value =  optIn;
          component.submitGuestCheckoutForm()
        },
        error: () => {
          component.submitGuestCheckoutForm()
        }
      });
    };

    const _submitGuestCheckoutForm = () => {
      component.guestCheckoutForm.submit();
    };

    component.config = _config;
    component.init = _init;
    component.checkIsSubscribed = _checkIsSubscribed;
    component.errorHandler = _errorHandler;
    component.successHandler = _successHandler;
    component.showGuestCheckoutNewsletterModal = _showGuestCheckoutNewsletterModal;
    component.closeGuestCheckoutNewsletterModal = _closeGuestCheckoutNewsletterModal;
    component.submitNewsletterPreferences = _submitNewsletterPreferences;
    component.submitGuestCheckoutForm = _submitGuestCheckoutForm;


    return component;
  };

  return guestNewsletterOptInModal;
});
